import { AfterViewInit, Component, enableProdMode, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { globalConfigService } from 'environments/environment.service';
// import { ThemeService } from './shared/services/theme.service';
import { filter } from 'rxjs/operators';
import { AppConfirmService } from './shared/services/app-confirm/app-confirm.service';
import { RoutePartsService } from "./shared/services/route-parts.service";
import { Portalconfiguration } from './views/Admin/admin.interface';
import { AdminService } from './views/Admin/admin.service';






enableProdMode();
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'MDA Hub';
  pageTitle = '';
  trycount: number=0;
  configjson: any;


  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    // private themeService: ThemeService,
    // private layout: LayoutService,
    // private renderer: Renderer2
    private glb: globalConfigService,
    private adminService: AdminService,
    private confirmService: AppConfirmService
    
  ) { }






  ngOnInit() {

this.configjson={};

    this.glb.load().subscribe((conf: any) => {
      this.configjson=conf;
      environment.HomepageHeaderBgColor = conf.HomepageHeaderBgColor;
      environment.HomepageHeaderColor = conf.HomepageHeaderColor;
          environment.LogoUrl = conf.LogoUrl;
          environment.LogoUrlHome = conf.LogoUrlHome;
      environment.apiURL = conf.apiURL;
      environment.BaseURL = conf.BaseURL;
      environment.apiURL = conf.apiURL;
      environment.DomainAuth = conf.DomainAuth;
      environment.defaultTheme=conf.defaultTheme;
      environment.CesuimToken=conf.CesuimToken;
      environment.HEREappKEY=conf.HEREappKEY;
      environment.HEREappId=conf.HEREappId;
      this.loadconffromAPI();




    }, err => {

      console.log(err)

    });
    this.changePageTitle();
  }


  loadconffromAPI() {

    this.adminService.GetPortalconfigurationsref().subscribe(res => {


      if (res.IsSuccess && res.data.length > 0) {
        var response = res.data[0] as Portalconfiguration;
 

         environment.LogoUrl = response.LogoUrl? response.LogoUrl:   environment.LogoUrl;
         environment.LogoUrlHome =   environment.LogoUrl?environment.LogoUrlHome:     environment.LogoUrlHome;
        environment.HomepageHeaderBgColor = response.HomepageHeaderBgColor? response.HomepageHeaderBgColor:   environment.HomepageHeaderBgColor;
        environment.HomepageHeaderColor = response.HomepageHeaderColor?response.HomepageHeaderColor:environment.HomepageHeaderColor;

        environment.DomainAuth = response.DomainAuth;
        environment.PortalTitle = response.PortalTitle;
        environment.loginImage = response.LoginImage;
        environment.updateinterval = response.Updateinterval;
        environment.LoginBackground = response.LoginBackground;
        environment.enableSignalR = response.EnableSignalR;
        environment.mapDefaultProjection ="EPSG:32639"// response.MapDefaultProjection;
        environment.debugMode = response.DebugMode;
        environment.defultGeoserver = response.Defultgeoserver;
        environment.AllowedModules = response.AllowedModules;
        environment.orgId = response.Orgid;

    

        this.glb.appConfigration$.next(environment);
        if (!environment.debugMode) {
          console.log = function () { };
          console.error == function () { };
        }

      }
      else

        this.trygetConfigration();

      console.log(res);

    }, err => {

      console.log(err);

      this.trygetConfigration();

    })


  }
  trygetConfigration() {

    this.trycount+=1;
  

      if (this.trycount < 3)
        this.loadconffromAPI();
      else
        this.getConfigrationfomConfigJSON()
 

  }

  getConfigrationfomConfigJSON() {
  
      environment.BaseURL = this.configjson.BaseURL;
      environment.LogoUrl = this.configjson.LogoUrl;
      environment.LogoUrlHome = this.configjson.LogoUrlHome;
      environment.apiURL = this.configjson.apiURL;
      environment.DomainAuth = this.configjson.DomainAuth;
      environment.PortalTitle = this.configjson.PortalTitle;
      environment.loginImage = this.configjson.loginImage;
      environment.updateinterval = this.configjson.updateinterval;
      environment.LoginBackground = this.configjson.LoginBackground;
      environment.enableSignalR = this.configjson.enableSignalR;
      environment.mapDefaultProjection = this.configjson.mapDefaultProjection;
      environment.debugMode = this.configjson.debugMode;
      environment.defultGeoserver = this.configjson.defultGeoserver;
      environment.AllowedModules = this.configjson.AllowedModules;
      environment.orgId = this.configjson.orgId;

      environment.HomepageHeaderBgColor = this.configjson.HomepageHeaderBgColor;
      environment.HomepageHeaderColor = this.configjson.HomepageHeaderColor;
      environment.HEREappKEY=this.configjson.HEREappKEY;
      environment.HEREappId=this.configjson.HEREappId;

      this.glb.appConfigration$.next(environment);

      if (!environment.debugMode) {
        console.log = function () { };
        console.error == function () { };
      }

   


  }
  ngAfterViewInit() {
  }
  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      var routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length)
        return this.title.setTitle(this.appTitle);
      // Extract title from parts;
      this.pageTitle = routeParts
        .reverse()
        .map((part) => part.title)
        .reduce((partA, partI) => { return `${partA} > ${partI}` });
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }

}
