// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.ts` will be used instead.
// The list of which env maps to which file can be found in `angular.json`.


export const environment = {
  production: false,
  CesuimToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJjNWQ1MmY2ZS04NTJlLTRhMTctYmNmNi04YmNkODM1NjE5ODMiLCJpZCI6NDM2MDQsImlhdCI6MTYxMjg2MDMzNX0.Tohjvk_MxpfLCtd6JVOm8Vr57yjbQ2_jgp2PpggOw2M",
  apiURL: "../api",
  LogoUrl: "./assets/images/logo/mda_logo.png",
  LogoUrlHome: "./assets/images/logo/mda_logo.png",
  loginImage: "./assets/images/logo/mda_logo.png",
  LoginBackground: "./assets/images/logo/backgroundMda.png",
  storageURL: "http://vgishub.com:5050/uploadedfiles/",

  DomainAuth: false,
  loggedin: false,
  TempObject: null,
  updateinterval: 60000,
  HomepageHeaderBgColor: "#000001",
  HomepageHeaderColor: "#fff",
  orgId: 5,
  isFirstLogintime: true,
  AllowedModules: [0, 1, 3, 4, 5],
  enableSignalR: false,
  debugMode: false,
  mapDefaultProjection: "EPSG:3857",
  PortalTitle: "MDA Hub",
  BaseURL: "/",
  defultGeoserver: "",
  encKey: "oihflhw-lrn8oq7-5475o3-4n3287-4ifewyf-89734h3j-wrjkh3-76b3rjwh-fjsf",
  defaultTheme: "MdaHubMain",
  HEREappId: "Pwu12FC6ZSbpKJCFQnRW",
  HEREappKEY: "qDNFBpCF9aQKTsmSzAs_fp4K5m-jGzwiLxgaI2D1_Zg"
};
