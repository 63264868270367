<div id="appheadertopDiv" class="header-topnav  " color="primary"   #appheadertopDiv >
    <!-- mat-elevation-z2 -->
    <div class="container">
        <div class="topnav">
            <!-- App Logo -->
            <div class="topbar-branding">
                <img src="{{ logo }}" alt="" class="app-logo" />
                <!-- <span style="
       
            padding: 7px;
            font-weight: bold;
          ">{{ appname }}</span> -->
            </div>
            <span fxFlex></span>
            <ul class="menu" *ngIf="!layoutConf.isMobile">
                <li *ngFor="let item of menuItems; let i = index">
                    <div *ngIf="item.type !== 'separator'" routerLinkActive="open">
                        <a matRipple (click)="goToPage(item.state)" *ngIf="item.type === 'link'">
                            <mat-icon>{{ item.icon }}</mat-icon>
                            {{ item.name | translate }}
                        </a>
                        <!--routerLink="/{{ item.state }}" 
                            [queryParams]="{map: column.Id}" -->
                        <a matRipple href="{{ item.state }}" *ngIf="item.type === 'url'">
                            <mat-icon>{{ item.icon }}</mat-icon>
                            {{ item.name | translate }}
                        </a>
                        <div *ngIf="item.type === 'dropDown'">
                            <label matRipple for="drop-{{ i }}" class="toggle">
                                <mat-icon>{{ item.icon }}</mat-icon> {{ item.name | translate }}
                            </label>
                            <a matRipple>
                                <mat-icon>{{ item.icon }}</mat-icon> {{ item.name | translate }}
                            </a>
                            <input type="checkbox" id="drop-{{ i }}" />
                            <ul>
                                <li *ngFor="let itemLvL2 of item.sub; let j = index" routerLinkActive="open">
                                    <a matRipple routerLink="{{ item.state ? '/' + item.state : '' }}/{{
                      itemLvL2.state
                    }}" *ngIf="
                      itemLvL2.type !== 'dropDown' && itemLvL2.type !== 'url'
                    ">
                                        <mat-icon *ngIf="itemLvL2.icon">{{ itemLvL2.icon }}
                                        </mat-icon>
                                        {{ itemLvL2.name | translate }}
                                    </a>

                                    <a matRipple href="{{ itemLvL2.state }}" *ngIf="
                      itemLvL2.type !== 'dropDown' && itemLvL2.type == 'url'
                    ">
                                        <mat-icon *ngIf="itemLvL2.icon">{{ itemLvL2.icon }}
                                        </mat-icon>
                                        {{ itemLvL2.name | translate }}
                                    </a>

                                    <div *ngIf="itemLvL2.type === 'dropDown'">
                                        <label matRipple for="drop-{{ i }}{{ j }}" class="toggle">{{
                                            itemLvL2.name | translate
                                            }}</label>
                                        <a matRipple>
                                            <mat-icon *ngIf="itemLvL2.icon">{{ itemLvL2.icon }}
                                            </mat-icon>
                                            {{ itemLvL2.name | translate }}
                                        </a>

                                        <input type="checkbox" id="drop-{{ i }}{{ j }}" />
                                        <!-- Level 3 -->
                                        <ul>
                                            <li *ngFor="let itemLvL3 of itemLvL2.sub" routerLinkActive="open">
                                                <a matRipple *ngIf="itemLvL3.type !== 'url'" routerLink="{{ item.state ? '/' + item.state : '' }}{{
                            itemLvL2.state ? '/' + itemLvL2.state : ''
                          }}/{{ itemLvL3.state }}">
                                                    <mat-icon *ngIf="itemLvL3.icon">{{ itemLvL3.icon }}
                                                    </mat-icon>
                                                    {{ itemLvL3.name | translate }}
                                                </a>

                                                <a matRipple *ngIf="itemLvL3.type === 'url'" routerLink="{{ itemLvL3.state }}">
                                                    <mat-icon *ngIf="itemLvL3.icon">{{ itemLvL3.icon }}
                                                    </mat-icon>
                                                    {{ itemLvL3.name | translate }}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
            <span fxFlex></span>


            <egret-search-input-over *ngIf="isLogged  && !layoutConf.isMobile" placeholder="Search for Layers , Maps , Groups , Apps , Users and more ... " resultPage="/search">
            </egret-search-input-over>
            <!-- End Navigation -->

            <!-- Language Switcher -->
            <button mat-button [matMenuTriggerFor]="menulang">
                <span class="flag-icon {{ currentLang.flag }} mr-05"></span>
                <span>{{ currentLang.alt }}</span>
            </button>
            <mat-menu #menulang="matMenu">
                <button mat-menu-item *ngFor="let lang of availableLangs" (click)="setLang(lang)">
                    <span class="flag-icon mr-05 {{ lang.flag }}"></span>
                    <span>{{ lang.alt }}</span>
                </button>
            </mat-menu>

            <!-- Theme Switcher -->
            <!-- <button *ngIf="isLogged && !layoutConf.isMobile" mat-icon-button [matMenuTriggerFor]="themeMenu" class="topbar-button-right">
                <mat-icon>format_color_fill</mat-icon>
            </button>
            <mat-menu #themeMenu="matMenu">
                <mat-grid-list class="theme-list" cols="2" rowHeight="48px" >
                    <mat-grid-tile *ngFor="let theme of mdaHubThemes" (click)="changeTheme(theme)">
                        <div mat-menu-item [title]="theme.name">
                            <div [style.background]="theme.baseColor" class="egret-swatch"></div>
                            <mat-icon class="active-icon" *ngIf="theme.isActive">check</mat-icon>
                        </div>
                    </mat-grid-tile>
                </mat-grid-list>
            </mat-menu> -->
            <!-- Notification toggle button -->
            <!-- && notificationService.notifCount>0 -->
            <button mat-icon-button *ngIf="isLogged" matTooltip="Notifications" (click)="toggleNotific()" [style.overflow]="'visible'" class="topbar-button-right">
                <mat-icon>notifications</mat-icon>
                <span class="notification-number mat-bg-warn">{{
                    notificationService
                    ? notificationService.notifCount == 0
                    ? "0"
                    : notificationService.notifCount
                    : "0"
                    }}</span>
            </button>

            <button mat-icon-button *ngIf="isLogged && enableChat && !layoutConf.isMobile" matTooltip="Chat" (click)="showChat()" [style.overflow]="'visible'" class="topbar-button-right">
                <mat-icon>chat</mat-icon>
            </button>

            <!-- Top left user menu -->
            <div *ngIf="!isLogged " style="padding-right: 20px; cursor: pointer; " (click)="openSignIN()" class="menuBtn">
                Sign In
            </div>

            <button *ngIf="isLogged && !layoutConf.isMobile " mat-icon-button [matMenuTriggerFor]="accountMenu" class="topbar-button-right mr-1 img-button">
                <img src="{{  jwtAuth?.user?.PictureUrl }}"
                    onerror="this.onerror=null;this.src='./assets/profileImages/default4.png';" alt="" />
            </button>
            <mat-menu #accountMenu="matMenu">
                <button mat-menu-item [routerLink]="['/profile/overview']">
                    <mat-icon>account_box</mat-icon>
                    <span>Profile</span>
                </button>
                <button mat-menu-item [routerLink]="['/profile/settings']">
                    <mat-icon>settings</mat-icon>
                    <span>Account Settings</span>
                </button>
                <button mat-menu-item>
                    <mat-icon>notifications_off</mat-icon>
                    <span>Disable alerts</span>
                </button>

                <button mat-menu-item (click)="signout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Sign out</span>
                </button>
            </mat-menu>
            <!-- Mobile screen menu toggle -->
            <button mat-icon-button class="mr-1" (click)="toggleSidenav()" *ngIf="layoutConf.isMobile">
                <mat-icon>menu</mat-icon>
            </button>
        </div>
    </div>

    <app-message-chat #messageChatComponen *ngIf="isLogged"></app-message-chat>
</div>