 import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";
import { WebApiResult } from 'app/shared/models/apiresult';
import { Portalconfiguration } from './admin.interface';
 
@Injectable({
  providedIn: 'root'
})
export class AdminService { 

  constructor(
    private http: HttpClient
  ) { }

  GetAllRoles(){
    return this.http.get(environment.apiURL + "/api/Admin/getAllRoles").pipe(
      map((response:WebApiResult) => {

        return response;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }

  DeleteRoleAndPrivilage(id){
    return this.http.delete(`${environment.apiURL}/api/Admin/DeleteRoleAndPrivilage?id=${id}`).pipe(
      map((response:WebApiResult) => {
        return response;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }

 
  UpdatePortalconfiguration(portalconfiguration:Portalconfiguration){
    return this.http.put(`${environment.apiURL}/api/Portalconfiguration/UpdatePortalconfiguration?id=${portalconfiguration.Id}`,portalconfiguration).pipe(
      map((response:WebApiResult) => {

        return response;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }

  AddPortalconfiguration(portalconfiguration:Portalconfiguration){
    return this.http.post(`${environment.apiURL}/api/Portalconfiguration/AddPortalconfiguration`,portalconfiguration).pipe(
      map((response:WebApiResult) => {

        return response;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }

  getPortalconfiguration(orgId){
    return this.http.get(`${environment.apiURL}/api/Portalconfiguration/GetPortalconfigurationsByOrgId?orgId=${orgId}`).pipe(
      map((response:WebApiResult) => {

        return response;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }


  GetPortalconfigurationsref(){
    return this.http.get(`${environment.apiURL}/api/Portalconfiguration/GetPortalconfigurationsref`).pipe(
      map((response:WebApiResult) => {

        return response;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }

  GetAllPrivilege(){
    return this.http.get(environment.apiURL + "/api/Admin/GetAllPrivileges").pipe(
      map((response:WebApiResult) => {

        return response;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }
  getlogins( lastdays){
    return this.http.get(environment.apiURL + "/api/User/getlogins?lastdays="+ lastdays ).pipe(
      map((response:WebApiResult) => {

        return response;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }

  GetPrivilegeByRole(roleId){
    return this.http.get(`${environment.apiURL}/api/Admin/GetPrivilegesByRole?roleId=${roleId}`).pipe(
      map((response) => {

        return response;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }

  UpdateRoleAndPrivilege(RoleAndPrivilege: object){
    
    return this.http.post(`${environment.apiURL}/api/Admin/UpdateRoleAndPrivilege`, RoleAndPrivilege).pipe(
      map((response) => {

        return response;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }


  AddRoleAndPrivilage(RoleAndPrivilege: object){
    return this.http.post(`${environment.apiURL}/api/Admin/AddRoleAndPrivilage`, RoleAndPrivilege).pipe(
      map((response) => {

        return response;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }

  GetAllUsers(){
    return this.http.get(environment.apiURL + "/api/Admin/GetAllUsers").pipe(
      map((response:WebApiResult) => {

        return response;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }

  UpdateUser(user: object){
    return this.http.post(`${environment.apiURL}/api/Admin/UpdateUser`, user).pipe(
      map((response:WebApiResult) => {

        return response;
      }),
      catchError(error => {
        return throwError(error);
      }));
  }

}