import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { GuestLayoutComponent } from './shared/components/layouts/guest-layout/guest-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { MapLayoutComponent } from './shared/components/layouts/Map-layout/map-layout.component';
import { Rolesconfig } from 'Rolesconfig';
import { UserRoleGuard } from './shared/guards/user-role.guard';
import { TopbarFooterLayoutComponent } from './shared/components/layouts/topbar-footer-layout/topbar-footer-layout.component';
import { ApplicationLayoutComponent } from './shared/components/layouts/Application-Layout/Application-Layout.component';
import { EmptyLayoutComponent } from './shared/components/layouts/Empty-Layout/empty-layout.component';
import { environment } from 'environments/environment';
export const rootRouterConfig: Routes = [



  {
    path: '',
    component: GuestLayoutComponent,
    children: [
      {
        path: '',

        loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule),
        data: { title:  environment.PortalTitle, roles: Rolesconfig.authRoles.guest }
      },
      {
        path: 'home',

        loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule),
        data: { title:  environment.PortalTitle, roles: Rolesconfig.authRoles.guest }
      },

      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: ' MDA Hub', roles: Rolesconfig.authRoles.guest }
      }
    ]
  },

  {
    path: '',
    component: MapLayoutComponent,
   
    data: { title:  environment.PortalTitle, breadcrumb: 'MAP', roles: Rolesconfig.authRoles.user },
    children: [
      {
        path: 'map',
        loadChildren: () => import('./views/map/master-map/ol/OL-AppMap.module').then(m => m.AppMapmodule),
        data: { title:  environment.PortalTitle, breadcrumb: 'MAP', roles: Rolesconfig.authRoles.user }
      },
      {
        path: 'esri',
        loadChildren: () => import('./views/map/master-map/esri/esri-master-map.module').then(m => m.EsriMasterMapModule),
        data: { title:  environment.PortalTitle, breadcrumb: 'MAP', roles: Rolesconfig.authRoles.user }
      }
    ,
      {
        path: 'scene',
        loadChildren: () => import('./views/scene/mdahub-scene.module').then(m => m.MDAHubSceneModule),
        data: { title:  environment.PortalTitle, breadcrumb: 'MAP', roles: Rolesconfig.authRoles.user }
      }
    ]
  },


  {
    path: '',
    component: TopbarFooterLayoutComponent,
    canActivate: [AuthGuard, UserRoleGuard],
    data: { title:  environment.PortalTitle, breadcrumb: 'Group', roles: Rolesconfig.authRoles.user },
    children: [
      {
        path: 'content',

        loadChildren: () => import('./views/my-content/my-content.module').then(m => m.ContentModule),
        data: { title:  environment.PortalTitle, breadcrumb: 'My Contents', roles: Rolesconfig.authRoles.user }
      }
      ,
      {
        path: 'search',

        data: { title:  environment.PortalTitle, breadcrumb: 'Group', roles: Rolesconfig.authRoles.user },
        loadChildren: () => import('./views/search-view/search-view.module').then(m => m.SearchViewModule)
      },
      {
        path: 'group',

        loadChildren: () => import('./views/groups/groups.module').then(m => m.GroupsModule),
        data: { title:  environment.PortalTitle, breadcrumb: 'Group', roles: Rolesconfig.authRoles.user }
        },
        {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
        data: { title: 'Profile', breadcrumb: 'PROFILE', roles: Rolesconfig.authRoles.user }
        },

        {
            path: 'jobs',
            loadChildren: () => import('./views/jobs/jobs.module').then(m => m.JobsModule),
            data: { title: environment.PortalTitle, breadcrumb: 'Group', roles: Rolesconfig.authRoles.user }
        }

    ]
  },
  {
    path: '',
    component: TopbarFooterLayoutComponent,
    canActivate: [AuthGuard, UserRoleGuard],
    data: { title:  environment.PortalTitle, breadcrumb: 'FieldOperation', roles: Rolesconfig.authRoles.user },
    children: [
      {
        path: 'operation',

        loadChildren: () => import('./views/field-operation/field-operation.module').then(m => m.FieldOperationModule),
        data: { title:  environment.PortalTitle, breadcrumb: 'Operation', roles: Rolesconfig.authRoles.user }
      }
    ]
  },
  {
    path: '',
    component: EmptyLayoutComponent,
    canActivate: [AuthGuard, UserRoleGuard],
    data: { title:  environment.PortalTitle, breadcrumb: 'FieldOperation', roles: Rolesconfig.authRoles.user },
    children: [
      {
        path: 'eviewer',

        loadChildren: () => import('./views/field-operation/field-operation.module').then(m => m.FieldOperationModule),
        data: { title: environment.PortalTitle, breadcrumb: 'Operation', roles: Rolesconfig.authRoles.user }
      }
    ]
  },

  {
    path: '',
    component: TopbarFooterLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'admin',

        loadChildren: () => import('./views/Admin/admin.module').then(m => m.AdminModule),
        data: { title:  environment.PortalTitle, breadcrumb: 'privilage', roles: Rolesconfig.authRoles.sa }
      }
    ]
  },

  {
    path: '',
    component: ApplicationLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'app',
        loadChildren: () => import('./views/ApplicationsGallery/applications-gallery.module').then(m => m.ApplicationsGalleryModule),
        data: { title: 'Apps ', breadcrumb: 'Apps', roles: Rolesconfig.authRoles.user }
      }
    ]
  },

  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

