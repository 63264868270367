import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DefaultMenu_notLogin } from 'app/shared/models/menu.model';
import { initialUserState, IUser } from 'app/shared/models/user.model';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { NavigationService } from "../../../shared/services/navigation.service";
import { ThemeService } from '../../../shared/services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { MessageChatComponent } from '../message-chat/message-chat.component';
import { NotificationService } from '../notifications/notification.service';


@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html'
})
export class HeaderTopComponent implements OnInit, OnDestroy, AfterViewInit {
  public apiURL = environment.apiURL;
  public searchisfoucs: boolean = false;
  logo: string = "./assets/images/logo/mda_logo.png";
  layoutConf: any;
  menuItems: any;
  menuItemSub: Subscription;
  mdaHubThemes: any[] = [];
  public userInfo: IUser = initialUserState;
  public availableLangs = [{
    name: 'EN',
    alt: 'EN',
    code: 'en',
    flag: 'flag-icon-us'
  }, {
    name: 'Ar',
    alt: 'ع',
    code: 'ar',
    flag: 'flag-icon-kw'
  }]
  // {
  //   name: 'ES',
  //   alt: 'ES',
  //   code: 'es',
  //   flag: 'flag-icon-es'
  // }, 
  currentLang = this.availableLangs[0];
  @Input("notificPanel") notificPanel;
  @ViewChild("appheadertopDiv") private appheadertopDiv: ElementRef;
  @ViewChild("messageChatComponen") messageChatComponen: MessageChatComponent;
  public isLogged: boolean = false;
  public appname: string;
  enableChat: boolean;
  isdomainlogin: boolean;
  HomepageHeaderBgColor: string;
  constructor(

    private layout: LayoutService,
    private navService: NavigationService,
    public themeService: ThemeService,
    public translate: TranslateService,
    public jwtAuth: JwtAuthService,
    public notificationService: NotificationService,
    private router: Router,
    private cookieService: CookieService,
    private route: ActivatedRoute,

  ) { 


  }
  ngAfterViewInit(): void {
    var self=this;

    if(      this.router.url.indexOf("home")>-1   ||  this.router.url=="/" ){
      self.logo = environment.LogoUrlHome;
      self.appheadertopDiv.nativeElement.style.backgroundColor=environment.HomepageHeaderBgColor;
      self.appheadertopDiv.nativeElement.style.color= environment.HomepageHeaderColor;
      }
      else{
      self.logo = environment.LogoUrl;
      self.appheadertopDiv.nativeElement.style.backgroundColor="";
      self.appheadertopDiv.nativeElement.style.color="";
      }

 
    this.router.events.subscribe((val:any) => {
 if(val.url ){
      if(  val.url.indexOf("home")>-1  ||  this.router.url=="/"  ){
        self.logo = environment.LogoUrlHome;
        self.appheadertopDiv.nativeElement.style.backgroundColor=environment.HomepageHeaderBgColor;
        self.appheadertopDiv.nativeElement.style.color= environment.HomepageHeaderColor;
        }
        else{
        self.logo = environment.LogoUrl;
        self.appheadertopDiv.nativeElement.style.backgroundColor="";
        self.appheadertopDiv.nativeElement.style.color="";
        }}

    });

       
  
    }

  ngOnInit() {

    this.enableChat = environment.enableSignalR;
    this.isdomainlogin = environment.DomainAuth;
    this.jwtAuth.user$.subscribe(res => {
      if (res == null)
        this.navService.menuItems.next(DefaultMenu_notLogin);
    })


  
 
  

    
  

    this.appname = environment.PortalTitle;
    this.layoutConf = this.layout.layoutConf;
    this.mdaHubThemes = this.themeService.mdaHubThemes;
    this.currentLang = this.availableLangs[0];
    if (this.cookieService.check("lang")) {
      this.availableLangs.forEach(lan => {
        if (this.cookieService.get("lang") == lan.code) {
          this.currentLang = lan;
        }
      });
    }



    this.translate.use(this.currentLang.code);

    if (this.currentLang.code == "ar")
      this.layout.publishLayoutChange({ dir: "rtl" });
    else
      this.layout.publishLayoutChange({ dir: "ltr" });



    this.menuItemSub = this.navService.menuItems$.subscribe(res => {
      res = res.filter(item => item.type !== 'icon' && item.type !== 'separator' &&
        ((this.jwtAuth.getUser() && item.RoleId <= this.jwtAuth.getUser().RoleId) || item.RoleId == undefined));
      let limit = 7
      let mainItems: any[] = res.slice(0, limit)
      this.userInfo = this.jwtAuth.user;
      if (res.length <= limit) {

        return this.menuItems = mainItems
      }
      let subItems: any[] = res.slice(limit, res.length - 1)
      mainItems.push({
        name: 'More',
        type: 'dropDown',
        tooltip: 'More',
        icon: 'more_horiz',
        sub: subItems
      })

      this.menuItems = mainItems
    })

    this.autoSignIn();

    this.jwtAuth.user$.subscribe((user: IUser) => {
      if (user)
        this.isLogged = true;
      else
        this.isLogged = false;


    })
  }
  signout() {
    this.navService.menuItems.next(DefaultMenu_notLogin);

    this.isLogged = false;
    this.jwtAuth.signout();
  }

  Search(event) {
    var key = event.currentTarget.value;
    if (key.length > 2) {
      this.router.navigateByUrl('search?key=' + key)

    }

  }

  showChat() {
    this.messageChatComponen.ShowhideWindow();
  }

  setfoucs(foucs) {
    this.searchisfoucs = foucs;
  }
  getstylesearch() {
    if (this.searchisfoucs)
      return { "opacity": "1" }
    else
      return { "opacity": "0.3" }
  }
  autoSignIn() {


    this.jwtAuth.AutoSigninByToken()
      .subscribe(response => {
        if (response.IsSuccess) {
          this.navService.menuItems.next(this.jwtAuth.ls.getItem("MdaHub_APP_USER").MenuItems);
          this.isLogged = true;

        }




      }, err => {


      })


  }



  openSignIN() {
    var x = environment;
    if (environment.DomainAuth) {
      this.router.navigateByUrl("sessions/login");
    }
    else
      this.router.navigateByUrl('sessions/signin')

  }

  openSignUp() {


    this.router.navigateByUrl('sessions/signup')

  }

  ngOnDestroy() {
    this.menuItemSub.unsubscribe()
  }
  setLang(lng) {

    this.currentLang = lng;
    this.translate.use(lng.code);
    this.cookieService.set("lang", lng.code);

    if (this.currentLang.code == "ar")
      this.layout.publishLayoutChange({ dir: "rtl" });
    else
      this.layout.publishLayoutChange({ dir: "ltr" });



  }
  changeTheme(theme) {

    //  this.cookieService.set("apptheme", theme.name);
    this.layout.publishLayoutChange({ matTheme: theme.name })
  }

  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  goToPage(pathTo) {
    if (pathTo == 'map' || pathTo == 'scene') {

      if (this.route.snapshot.queryParams.map
      ) {
        this.router.navigate([`/${pathTo}`], {
          queryParams: {
            map: this.route.snapshot.queryParams.map
          }
        });

      }
      else {
        this.router.navigate([`/${pathTo}`]);

      }

    }
    else {
      this.router.navigate([`/${pathTo}`]);

    }

  }
}

