import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AppLoaderComponent } from './app-loader.component';

interface Config {
  width?: string
}

@Injectable()
export class AppLoaderService {
  dialogRef: MatDialogRef<AppLoaderComponent>;
  constructor(
    public componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private dialog: MatDialog) { }

  public open(title: string = '', config: Config = {width: '150px'})  {



  //   this.dialogRef = this.dialog.open(AppLoaderComponent, { disableClose: false, backdropClass: 'light-backdrop'});
  //   this.dialogRef.updateSize(config.width);
  //   this.dialogRef.componentInstance.title = title;
  //  // return this.dialogRef.afterClosed();




if(document.getElementById("apploadermaindiv"))
 document.getElementById("apploadermaindiv").remove();


    
    var componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      AppLoaderComponent
    );
    var componentRef = componentFactory.create(this.injector);
    this.appRef.attachView(componentRef.hostView);
    var domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

      
    
    domElem.style.zIndex ="10000";
    domElem.id="apploadermaindiv";
    var MapMainContainer = document.getElementsByTagName("body")[0];
    domElem.setAttribute("class", "widgetcls");
 
    var cal = "postion";
 
   
    domElem.setAttribute("class", "app-loader");
    MapMainContainer.appendChild(domElem);
    if( title==  null || title== undefined||  title=="" )
    document.getElementById("apploadertitle99").parentElement.remove()
    else
 
  document.getElementById("apploadertitle99").innerHTML=title?title:"please wait";
 

  }

  public update(title: string ="", config: Config = {width: '150px'})  {
   
   var self=this;

   if( title==  null || title== undefined||  title=="" )
   document.getElementById("apploadertitle99").parentElement.remove()
   else
 if(document.getElementById("apploadertitle99"))
 document.getElementById("apploadertitle99").innerHTML=title?title:"please wait";

  else
  this.open(title,config);
 
  }
  public close() {

    if(document.getElementById("apploadermaindiv"))
 document.getElementById("apploadermaindiv").remove();
      if(this.dialogRef)
       this.dialogRef.close();
  }
}
